export const IT = {
  TITLE_PAGE_GEST_PROC: 'Gestione procedimenti',
  TITLE_PAGE_LIST_PROC: 'Lista procedimenti',
  TITLE_PAGE_NEW_PROC: 'Nuovo procedimento',
  TITLE_PAGE_EDIT_PROC: 'Modifica procedimento',
  TITLE_PAGE_DETAIL_PROC: 'Dettaglio procedimento',
  TITLE_PAGE_GEST_LINGUA: 'Gestione lingua',
  TITLE_PAGE_GEST_TAG: 'Gestione tag',
  TITLE_PAGE_GEST_PROC_CORREL: 'Gestione procedimenti correlati',
  TITLE_PAGE_GEST_ORG: 'Gestione organizzazioni',

  AUTOCOMPLETE_TRANSLATE_LABEL: 'Select label',
  AUTOCOMPLETE_TRANSLATE_PLACEHOLDER: 'Select placeholder',
  AUTOCOMPLETE_TRANSLATE_PLACEHOLDER_ALL: 'Tutti',

  TEXT_TRANSLATE_LABEL: 'Text label',
  TEXT_TRANSLATE_PLACEHOLDER: 'Text placeholder',

  IT_LANGUAGE_ADJECTIVE: '(italiano)',
  EN_LANGUAGE_ADJECTIVE: '(inglese)',
  FR_LANGUAGE_ADJECTIVE: '(francese)',
  DE_LANGUAGE_ADJECTIVE: '(tedesco)',

  STATE_: '',
  STATE_BOZZA: 'Bozza',
  STATE_DA_PUBBLICARE: 'Da pubblicare',
  STATE_PUBBLICATO: 'Pubblicato',
  // TOASTR
  UTILS_TOASTR_ERROR_GENERIC_TITLE: 'Errore',
  UTILS_TOASTR_ERROR_GENERIC_MESSAGE: 'C\'è stato un errore, riprova più tardi',
  UTILS_LINGUA_DISABILITATA: '(lingua non abilitata)',
  FORM_SAVE: 'Salva',
  FORM_UPDATE: 'Aggiorna',
  OK_CLOSE: 'OK, Chiudi',
  CLOSE_PUBBLICATION: 'Chiudi e Pubblica',
  FORM_FILTER: 'Filtra',
  FORM_RESET: 'Cancella',

  COMPONENT_FORM_TITLE: 'Componente form',
  SINGLE_INPUTS_TITLE: 'Form singoli input',

  UTILS_TABLE_EMPTY: 'Nessun elemento trovato',
  UTILS_CONFIRM: 'Conferma',
  UTILS_REMOVE: 'Rimuovi',
  UTILS_CANCEL: 'Annulla',
  UTILS_AUTOCOMPLETE_INSERT_MIN_CHARACTERS: 'Inserire almeno 3 caratteri',
  UTILS_AUTOCOMPLETE_LOADING: 'Caricamento...',
  UTILS_AUTOCOMPLETE_ITEMS_NOT_FOUND: 'Nessun elemento trovato',
  UTILS_AUTOCOMPLETE_TYPE_TO_SEARCH: 'Digita per ricercare',
  UTILS_CLOSE: 'Chiudi',
  UTILS_ERROR: 'Errore',
  UTILS_WARNING: 'Attenzione',

  ACTIONS_FOOTER_SAVE: 'Salva',
  ACTIONS_FOOTER_UPDATE: 'Aggiorna',
  ACTIONS_FOOTER_CANCEL: 'Annulla',

  VALIDATIONS_REQUIRED: 'Campo richiesto',
  CIP_DATE_CREATION: 'Creato il ',
  CIP_CATEGORIE_LISTA_BUTTON_LANGUAGE: 'Gestione lingue',
  CIP_CATEGORIE_EDIT_LINGUAGE_BUTTON_EDIT: 'Modifica lingua',

  CIP_OFFICE_BUTTON_EDIT: 'Modifica ufficio',
  CIP_OFFICE_BUTTON_REMOVE: 'Rimuovi ufficio',

  CIP_DOCUMENT_BUTTON_DELETE: 'Cancella documento',

  CIP_CATEGORIE_LISTA_BUTTON_EDIT: 'Modifica',
  CIP_CATEGORIE_LISTA_BUTTON_DELETE: 'Elimina',
  CIP_CATEGORIE_LISTA_HEADER_TITLE: 'Lista Categorie procedimenti',
  CIP_CATEGORIE_LISTA_NEW_CATEGORY: 'Nuova categoria',
  CIP_CATEGORIE_LISTA_FILTER: 'Filtra categorie',
  CIP_CATEGORIE_LISTA_FILTER_PROCESSES: 'Filtra procedimento',
  CIP_CATEGORIE_LISTA_FILTER_NAME: 'Nome categoria',
  CIP_CATEGORIE_LISTA_FILTER_NAME_PLACEHOLDER: 'Cerca per nome',
  CIP_CATEGORIE_LISTA_FILTER_ID: 'Codice categoria',
  CIP_CATEGORIE_LISTA_FILTER_ID_PLACEHOLDER: 'Cerca per categoria',
  CIP_CATEGORIE_LISTA_TABLE_TH1: 'Nome categoria',
  CIP_CATEGORIE_LISTA_TABLE_TH2: 'Codice categoria',
  CIP_CATEGORIE_LISTA_TABLE_TH3: 'N. procedimenti',

  CIP_CATEGORIE_MANAGE_CATEGORIES: 'Gestione Categorie',
  CIP_CATEGORIE_RESULTS: 'risultati trovati',
  CIP_CATEGORIE_INSERT_LANG: 'Inserisci contenuti per la lingua',
  CIP_CATEGORIE_INPUT_LABEL: 'Nome categoria',
  CIP_CATEGORIE_INPUT_PLACEHOLDER: 'Inserisci nome categoria',

  CIP_CATEGORIE_ICON_PAGE_TITLE: 'Scegli icona categoria',
  CIP_CATEGORIE_ICON_PAGE_SUBTITLE: 'Seleziona l\'icona da associare alla categoria',

  CONCESSIONI_2: 'Scudo con check',
  CONCESSIONI: 'Bollino',
  ALTRI_PROCEDIMENTI: 'Mano con matita',
  CONTI_PAGAMENTI: 'Monete pagamenti',
  CONTATTI: 'Persone - utenti',
  FILE_ARCHIVIA: 'Categoria generica',

  CIP_CATEGORIE_DETAIL_GOTO_LIST: 'Torna alla lista',
  CIP_CATEGORIE_DETAIL_ICON_LABEL: 'Icona',
  CIP_CATEGORIE_DETAIL_ID_CAT: 'Codice categoria',
  CIP_CATEGORIE_DETAIL_NPROCESS: 'N. procedimenti',
  CIP_CATEGORIE_DETAIL_NAME_CATEGORY: 'Nome categoria ',
  CIP_CATEGORIE_DETAIL_MISSING_TRANSLATION: '---',
  CIP_CATEGORIE_DETAIL_TH1: 'Nome procedimento',
  CIP_CATEGORIE_DETAIL_TH2: 'Codice',
  CIP_CATEGORIE_DETAIL_TH3: 'Categoria',
  CIP_CATEGORIE_DETAIL_TH4: 'Stato',

  CIP_CATEGORIE_ID_LABEL: 'Codice categoria',
  CIP_CATEGORIE_ID_PLACEHOLDER: 'Inserisci codice categoria (alfanumerico)',
  CIP_CATEGORIE_ICON_LABEL: 'Icona',
  CIP_CATEGORIE_ICON_NAV_BUTTON: 'Scegli icona',
  CIP_CATEGORIE_NO_ICON: 'Inserire icona *obbligatorio',

  CIP_CATEGORIE_BREADCRUMB_HOME: 'Home',
  CIP_CATEGORIE_BREADCRUMB_ADMIN: 'Amministrazione',
  CIP_CATEGORIE_BREADCRUMB_MANAGE: 'Gestione categorie',
  CIP_CATEGORIE_BREADCRUMB_NEW: 'Nuova categoria',
  CIP_CATEGORIE_BREADCRUMB_EDIT: 'Modifica categoria',

  UTILS_FORM_LABEL_NOT_MANDATORY: 'facoltativo',

  CIP_NEW_CATEGORY_TITLE: 'Creazione',
  CIP_NEW_CATEGORY_MESSAGE: 'La categoria è stata creata con successo.',
  CIP_NEW_ERR_CATEGORY_TITLE: 'Errore Creazione',
  CIP_NEW_ERR_CATEGORY_MESSAGE:
    'Si è verificato un errore durante la creazione della categoria, riprova più tardi',
  CIP_EDIT_CATEGORY_TITLE: 'Modifica',
  CIP_EDIT_CATEGORY_MESSAGE: 'La categoria è stata modificata con successo.',
  CIP_EDIT_ERR_CATEGORY_TITLE: 'Errore Modifica',
  CIP_EDIT_ERR_CATEGORY_MESSAGE:
    'Si è verificato un errore durante la modifica della categoria.',
  CIP_CATEGORIE_DELETE_TITLE: 'Rimuovi Categoria',
  CIP_CATEGORIE_DELETE_TEXT:
    'Confermi di voler rimuovere la categoria selezionata?',
  CIP_CATEGORIE_DELETE_ERROR_TITLE: 'Errore',
  CIP_CATEGORIE_DELETE_ERROR_NPROC_TEXT:
    'Le categorie con procedimenti associati non possono essere eliminate.',
  CIP_CATEGORIE_DELETE_ERROR_TEXT:
    'Non è stato possibile eliminare la categoria selezionata, riprova più tardi',

  CIP_CATEGORIE_FETCH_ERROR_TITLE: 'Errore',
  CIP_CATEGORIE_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare le categorie, riprova più tardi',

  CIP_CATEGORIE_RESOLVER_ERROR_LANG_TITLE: 'Errore',
  CIP_CATEGORIE_RESOLVER_ERROR_LANG_TEXT:
    'Si è verificato un errore durante il recupero delle lingue abilitate.',

  CIP_CATEGORIE_RESOLVER_ERROR_CAT_TITLE: 'Errore',
  CIP_CATEGORIE_RESOLVER_ERROR_CAT_TEXT:
    'Si è verificato un errore durante il recupero della categoria da modificare.',

  CIP_CATEGORIE_DELETE_SUCCESS_TITLE: 'Categoria eliminata',
  CIP_CATEGORIE_DELETE_SUCCESS_TEXT:
    'La categoria selezionata è stata correttamente eliminata',

  // TAG SECTION
  CIP_TAG_LIST_TITLE: 'Lista Tipo Tag',
  CIP_TAG_LIST_SUBTITLE: ' risultati trovati',
  CIP_TAG_LIST_BUTTON_NEW_TAG: 'Nuovo Tag',

  CIP_TAG_LIST_BUTTON_NEW_KIND_TAG: 'Nuovo tipo',

  CIP_TAG_LIST_FILTER_TEXT: 'Filtra categoria',

  CIP_TAG_BREADCRUMB_HOME: 'Home',
  CIP_TAG_BREADCRUMB_ADMIN: 'Amministrazione',
  CIP_TAG_BREADCRUMB_MANAGE: 'Gestione Tag',
  CIP_TAG_BREADCRUMB_NEW_KIND: 'Nuovo tipo Tag',
  CIP_TAG_BREADCRUMB_NEW_TAG: 'Nuovo Tag',
  CIP_TAG_BREADCRUMB_EDIT_TAG: 'Modifica Tag',
  CIP_TAG_BREADCRUMB_EDIT_KIND: 'Modifica tipo Tag',

  CIP_TAG_LIST_POP_CONFIRM_DELETE_TITLE: 'Rimuovi Tipo Tag',
  CIP_TAG_LIST_POP_CONFIRM_DELETE_TEXT:
    'Confermi di voler rimuovere il tipo Tag selezionato?',
  CIP_TAG_LIST_FILTER_FORM_CODE_LABEL: 'Codice tipo Tag',
  CIP_TAG_LIST_FILTER_FORM_CODE_PLACEHOLDER: 'Inserisci codice tipo Tag',
  CIP_TAG_LIST_FILTER_FORM_NAME_KIND_TAG_LABEL: 'Nome tipo Tag',
  CIP_TAG_LIST_FILTER_FORM_NAME_KIND_TAG_PLACEHOLDER: 'Inserisci tipo Tag',

  CIP_TAG_LIST_BUTTON_EXPORT: 'Esporta elenco',
  CIP_TAG_LIST_COLUMNS_KIND_TAG: 'Tipo Tag',
  CIP_TAG_LIST_BUTTON_EDIT: 'Modifica',
  CIP_TAG_LIST_BUTTON_MOVE_TAG: 'Sposta tag',
  CIP_TAG_LIST_BUTTON_LANGUAGES: 'Gestione lingue',
  CIP_TAG_LIST_BUTTON_DELETE: 'Elimina',
  CIP_TAG_LIST_COLUMNS_KIND_CODE: 'Codice tipo',

  CIP_TAG_LIST_COLUMNS_TAG_NUMBER: 'N. Tag',
  CIP_TAG_LIST_COLUMNS_DESCRIPTION: 'Descrizione',
  CIP_TAG_LIST_TOASTR_SUCCESS_DELETED_TITLE: 'Tipo Tag eliminato',
  CIP_TAG_LIST_TOASTR_SUCCESS_DELETED_MESSAGE:
    'Il tipo di Tag selezionato è stato correttamente eliminato',

  CIP_TAG_LIST_TOASTR_ERROR_DELETED_TITLE: 'Errore',
  CIP_TAG_LIST_TOASTR_ERROR_DELETED_MESSAGE:
    'Non è stato possibile eliminare il tipo Tag selezionato, riprova più tardi',

  CIP_TAG_LIST_FETCH_ERROR_TITLE: 'Errore',
  CIP_TAG_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i Tag, riprova più tardi',

  // dettaglio tipo tag
  CIP_TAG_DETAIL_KIND_TAG_CODE: 'Codice tipo',
  CIP_TAG_DETAIL_KIND_TAG_NUMBER: 'N. Tag',
  CIP_TAG_DETAIL_KIND_TAG_COLOR: 'Colore',
  CIP_TAG_DETAIL_KIND_TAG_DESCRIPTION: 'Descrizione',

  CIP_TAG_DETAIL_KIND_TAG_BUTTONS_TO_LIST: 'Torna alla lista',

  CIP_TAG_DETAIL_MODAL_TITLE: 'Sposta Tag',
  CIP_TAG_DETAIL_MODAL_TAG_NAME: 'Nome Tag',
  CIP_TAG_DETAIL_MODAL_TAG_CODE: 'Codice Tag',
  CIP_TAG_DETAIL_MODAL_TAG_ACTION_DESCRIPTION:
    'Seleziona il tipo da associare al Tag',

  CIP_TAG_DETAIL_MODAL_INPUT_CATEGORY_LABEL: 'Tipo di destinazione',
  CIP_TAG_DETAIL_MODAL_INPUT_CATEGORY_PLACEHOLDER:
    'Scegli tipo di destinazione',

  CIP_TAG_DETAIL_KIND_TAG_LANGUAGES_TITLE: 'Nome tipo',

  CIP_TAG_DETAIL_KIND_TAG_LIST_FILTER_BUTTON: 'Filtra Tag',
  CIP_TAG_DETAIL_KIND_TAG_LIST_FILTER_FORM_NAME_LABEL: 'Nome Tag',
  CIP_TAG_DETAIL_KIND_TAG_LIST_FILTER_FORM_NAME_PLACEHOLDER:
    'Inserisci nome Tag',
  CIP_TAG_DETAIL_KIND_TAG_LIST_FILTER_FORM_CODE_LABEL: 'Codice Tag',
  CIP_TAG_DETAIL_KIND_TAG_LIST_FILTER_FORM_CODE_PLACEHOLDER:
    'Inserisci codice Tag',

  CIP_TAG_DETAIL_POP_CONFIRM_TITLE: 'Rimuovi Tag',
  CIP_TAG_DETAIL_POP_CONFIRM_TEXT:
    'Confermi di voler rimuovere il Tag selezionato?',

  CIP_KIND_TAG_TOASTR_ERROR_DETAIL_TITLE: 'Errore',
  CIP_KIND_TAG_TOASTR_ERROR_DETAIL_MESSAGE:
    'C\'è stato un problema nel recuperare i dati del tipo Tag, riprova piu tardi',
  CIP_TAG_TOASTR_ERROR_TAG_DETAIL_TITLE: 'Errore',
  CIP_TAG_TOASTR_ERROR_TAG_DETAIL_MESSAGE:
    'C\'è stato un problema nel recuperare i dati del Tag, riprova piu tardi',
  CIP_TAG_TOASTR_LINGUE_TITLE: 'Errore',
  CIP_TAG_TOASTR_LINGUE_MESSAGE:
    'C\'è stato un problema nel recuperare le lingue censite nel sistema, riprova piu tardi',

  CIP_TAG_DETAIL_KIND_TAG_LIST_COLUMNS_NAME: 'Nome Tag',
  CIP_TAG_DETAIL_KIND_TAG_LIST_COLUMNS_CODE: 'Codice Tag',
  CIP_TAG_DETAIL_KIND_TAG_LIST_COLUMNS_DESCRIPTION: 'Descrizione',

  TOASTR_SUCCESS_CHANGED_CATEGORY_TITLE: 'Modifica effettuata',
  TOASTR_SUCCESS_CHANGED_CATEGORY_MESSAGE:
    'La categoria del Tag selezionato è stato correttamente cambiata',
  TOASTR_SUCCESS_DELETED_TAG_TITLE: 'Tag eliminato',
  TOASTR_SUCCESS_DELETED_TAG_MESSAGE:
    'Il Tag selezionato è stato correttamente eliminato',
  TOASTR_ERROR_DELETED_TAG_TITLE: 'Errore',
  TOASTR_ERROR_DELETED_TAG_MESSAGE:
    'Il Tag selezionato non è stato cancellato, riprova più tardi',

  // Nuovo tipo tag sezione
  CIP_NEW_KIND_TAG_TITLE: 'Nuovo tipo Tag',
  CIP_EDIT_KIND_TAG_TITLE: 'Modifica tipo Tag',

  CIP_EDIT_KIND_TAG_FORM_CODE_LABEL: 'Codice tipo',
  CIP_EDIT_KIND_TAG_FORM_CODE_PLACEHOLDER:
    'Inserisci codice tipo (alfanumerico)',

  CIP_NEW_KIND_TAG_TOASTR_SUCCESS_TITLE: 'Tipo Tag creato',
  CIP_NEW_KIND_TAG_TOASTR_SUCCESS_MESSAGE:
    'Il nuovo tipo Tag è stato correttamente creato',

  CIP_NEW_KIND_TAG_TOASTR_ERROR_TITLE: 'Errore',
  CIP_NEW_KIND_TAG_TOASTR_ERROR_MESSAGE:
    'Non è stato possibile creare il tipo Tag, riprova più tardi',

  CIP_EDIT_KIND_TAG_TOASTR_SUCCESS_TITLE: 'Tipo Tag modificato',
  CIP_EDIT_KIND_TAG_TOASTR_SUCCESS_MESSAGE:
    'La modifica al tipo Tag è stata correttamente eseguita',

  CIP_EDIT_KIND_TAG_TOASTR_ERROR_TITLE: 'Errore',
  CIP_EDIT_KIND_TAG_TOASTR_ERROR_MESSAGE:
    'Non è stato possibile salvare la modifica al tipo Tag, riprova più tardi',

  CIP_EDIT_KIND_TAG_FORM_COLOR_LABEL: 'Colore tipo',
  CIP_EDIT_KIND_TAG_FORM_COLOR_PLACEHOLDER: 'Scegli colore tipo',

  CIP_EDIT_KIND_TAG_FORM_DESCRIPTION_LABEL: 'Descrizione',
  CIP_EDIT_KIND_TAG_FORM_DESCRIPTION_PLACEHOLDER: 'Inserisci descrizione',

  CIP_EDIT_KIND_TAG_FORM_CONTENT: 'Inserisci contenuti per la lingua',
  CIP_EDIT_KIND_TAG_FORM_CONTENT_LABEL: 'Nome tipo',
  CIP_EDIT_TAG_FORM_TAG_CONTENT_LABEL: 'Nome Tag',

  CIP_EDIT_KIND_TAG_FORM_CONTENT_PLACEHOLDER: 'Inserisci nome tipo',
  CIP_EDIT_TAG_FORM_TAG_CONTENT_PLACEHOLDER: 'Inserisci nome Tag',

  // nuovo tag sezione
  CIP_NEW_TAG_TITLE: 'Nuovo Tag',
  CIP_EDIT_TAG_TITLE: 'Modifica Tag',
  CIP_EDIT_TAG_FORM_CODE_LABEL: 'Codice Tag',
  CIP_EDIT_TAG_FORM_CODE_PLACEHOLDER: 'Inserisci codice Tag',
  CIP_EDIT_KIND_TAG_FORM_KIND_LABEL: 'Tipo Tag',
  CIP_EDIT_KIND_TAG_FORM_KIND_PLACEHOLDER: 'Scegli tipo Tag',
  CIP_EDIT_TAG_FORM_DESCRIPTION_LABEL: 'Descrizione',
  CIP_EDIT_TAG_FORM_DESCRIPTION_PLACEHOLDER: 'Inserisci descrizione',

  CIP_NEW_TAG_TOASTR_SUCCESS_TITLE: 'Tag creato',
  CIP_NEW_TAG_TOASTR_SUCCESS_MESSAGE: 'Il Tag è stato correttamente creato',

  CIP_NEW_TAG_TOASTR_ERROR_TITLE: 'Errore',
  CIP_NEW_TAG_TOASTR_ERROR_MESSAGE:
    'Il Tag è non è stato correttamente creato, riprova',

  CIP_EDIT_TAG_TOASTR_SUCCESS_TITLE: 'Tag modificato',
  CIP_EDIT_TAG_TOASTR_SUCCESS_MESSAGE:
    'Il Tag è stato correttamente modificato',

  CIP_EDIT_TAG_TOASTR_ERROR_TITLE: 'Errore',
  CIP_EDIT_TAG_TOASTR_ERROR_MESSAGE:
    'Il Tag è non è stato correttamente modificato',

  CIP_EDIT_TAG_FORM_CONTENT: 'Inserisci contenuti per la lingua',
  CIP_EDIT_TAG_FORM_CONTENT_LABEL: 'Nome tipo',
  CIP_EDIT_TAG_FORM_CONTENT_PLACEHOLDER: 'Inserisci nome tipo',

  // PROCEDIMENTI SECTION
  CIP_PROCEDIMENTO_INPUT_LANGUAGE_LABEL: 'Seleziona lingua',
  CIP_PROCEDIMENTO_INPUT_LANGUAGE_PLACEHOLDER: 'Seleziona lingua',
  CIP_PROCEDIMENTO_DETAIL_CODE: 'Codice',
  CIP_PROCEDIMENTO_DETAIL_STRUCTURE: 'Struttura Organizzativa',
  CIP_PROCEDIMENTO_DETAIL_CATEGORY: 'Categoria',
  CIP_PROCEDIMENTO_DETAIL_STATE: 'Stato',
  CIP_PROCEDIMENTO_DETAIL_LEVEL_INTERACTION: 'Livello di interazione',
  CIP_PROCEDIMENTO_DETAIL_LEVEL_INFORMATION: 'Livello di informatizzazione',
  CIP_PROCEDIMENTO_TAB_DATA: 'Dati procedimento',
  CIP_PROCEDIMENTO_TAB_WHERE: 'Dove rivolgersi',
  CIP_PROCEDIMENTO_TAB_INFO: 'Info aggiuntive',
  CIP_PROCEDIMENTO_TAB_DOCUMENTS: 'Documenti',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MAIN_INFO: 'Informazioni principali',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_NOTE: 'Note',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_TAG: 'Informazioni principali',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_PROCEDIMENTI: 'Procedimenti correlati',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_TAGS: 'Tag',

  CIP_PROCEEDING_EDIT_MAIN_TITLE: 'Modifica procedimento',
  CIP_PROCEEDING_ADD_MAIN_TITLE: 'Nuovo procedimento',
  CIP_PROCEEDING_ADD_STEP1_TITLE: 'Dati Procedimento',
  CIP_PROCEEDING_ADD_STEP2_TITLE: 'Dove rivolgersi',
  CIP_PROCEEDING_ADD_STEP3_TITLE: 'Info procedimento',
  CIP_PROCEEDING_ADD_STEP4_TITLE: 'Documenti',
  CIP_PROCEEDING_ADD_STEP1_COD: 'Codice',
  CIP_PROCEEDING_ADD_STEP1_NOM: 'Titolo procedimento',
  CIP_PROCEEDING_ADD_STEP1_NOM_PLACEHOLDER: 'Inserisci titolo procedimento',
  CIP_PROCEEDING_ADD_STEP1_COD_PLACEHOLDER: 'Inserisci codice procedimento',
  CIP_PROCEEDING_ADD_STEP1_CAT: 'Categoria',
  CIP_PROCEEDING_ADD_STEP1_CAT_PLACEHOLDER: 'Seleziona una categoria',
  CIP_PROCEEDING_ADD_STEP1_LIV: 'Livello di informatizzazione',
  CIP_PROCEEDING_ADD_STEP1_STRUCTURE_TITLE: 'Struttura organizzativa',
  CIP_PROCEEDING_ADD_STEP1_LIST_STRUCTURE_TITLE:
    'Associa Strutture Organizzative',

  CIP_PROCEEDING_ADD_STEP1_STRUCT_BUTTON: 'Gestisci Organizzazioni',
  CIP_PROCEEDING_ADD_STEP1_STRUCTURE_LABEL: 'Aggiungi organizzazioni',
  CIP_PROCEEDING_ADD_STEP1_STRUCTURE_PLACEHOLDER:
    'Seleziona una o più organizzazioni',
  CIP_PROCEEDING_ADD_STEP1_STRUCT_NO_RESULT: 'Nessuna organizzazione associata',
  CIP_PROCEEDING_ADD_STEP1_STRUCT_COLUMN_DENOMINAZIONE: 'Denominazione',
  CIP_PROCEEDING_ADD_STEP1_STRUCT_COLUMN_CATEGORIA: 'Categoria',
  CIP_PROCEEDING_ADD_STEP1_STRUCT_COLUMN_CF: 'Codice Fiscale',
  CIP_PROCEEDING_ADD_STEP1_STRUCT_COLUMN_CU: 'Cod. ufficio',

  CIP_PROCEEDING_ADD_STEP1_TAG_TITLE: 'Tag',
  CIP_PROCEEDING_ADD_STEP1_RELATED_TITLE: 'Procedimenti correlati',
  CIP_PROCEEDING_ADD_STEP1_HOME_TITLE: 'Home Page',
  CIP_PROCEEDING_ADD_STEP1_HOME_LABEL: 'Lancia il procedimento in HP',
  CIP_PROCEEDING_ADD_STEP1_PRIO: 'Priorità',
  CIP_PROCEEDING_ADD_STEP1_PRIO_PLACEHOLDER: 'Numero per ordinamento in HP',
  CIP_PROCEEDING_ADD_STEP1_TAG_NO_RESULT: 'Nessun Tag associato',
  CIP_PROCEEDING_ADD_STEP1_TAG_BUTTON: 'Gestisci Tag',
  CIP_PROCEEDING_ADD_STEP1_RELATED_NO_RESULT: 'Nessun procedimento associato',
  CIP_PROCEEDING_ADD_STEP1_RELATED_BUTTON: 'Gestisci Correlati',
  CIP_PROCEEDING_ADD_STEP1_CANCEL_BUTTON: 'Annulla',
  CIP_PROCEEDING_ADD_STEP1_SAVE_BUTTON: 'Salva e continua',
  CIP_PROCEEDING_ADD_STEP1_LIV_PLACEHOLDER: 'Selezione un livello informativo',

  CIP_PROCEEDING_ADD_STEP1_TOASTR_SUECCESS_UPDATE_TITLE:
    'Procedimento modificato',
  CIP_PROCEEDING_ADD_STEP1_TOASTR_SUCCESS_TITLE: 'Procedimento creato',
  CIP_PROCEEDING_ADD_STEP1_TOASTR_SUCCESS_MESSAGE:
    'Il nuovo procedimento è stato inserito nel catalogo CIP',
  CIP_PROCEEDING_ADD_STEP1_TOASTR_SUCCESS_UPDATE_MESSAGE:
    'Il procedimento è stato correttamente modificato',
  CIP_PROCEDIMENTO_NEW_TOASTR_ERROR_CREATE_TITLE: 'Errore',
  CIP_PROCEDIMENTO_NEW_TOASTR_ERROR_CREATE_MESSAGE:
    "È stato riscontrato un errore durante la creazione del procedimento, riprovare più tardi",
  CIP_PROCEDIMENTO_NEW_TOASTR_ERROR_UPDATE_MESSAGE:
    "È stato riscontrato un errore durante la modifica del procedimento, riprovare più tardi",
  CIP_PROCEEDING_ADD_NEW_OFFICE_BUTTON: 'Nuovo Ufficio',
  CIP_PROCEEDING_ADD_NEW_OFFICE_TITLE: 'Nuovo Ufficio',
  CIP_PROCEEDING_ADD_NEW_OFFICE_NO_RESULT: 'Non sono presenti uffici',
  CIP_PROCEEDING_ADD_OFFICE_NAME_LABEL: 'Nome',
  CIP_PROCEEDING_ADD_OFFICE_NAME_PLACEHOLDER: 'Inserisci nome completo',
  CIP_PROCEEDING_ADD_OFFICE_ADDRESS_LABEL: 'Indirizzo',
  CIP_PROCEEDING_ADD_OFFICE_RESPONSABILE_PROCEDIMENTO_LABEL:
    'Responsabile procedimento',
  CIP_PROCEEDING_ADD_OFFICE_ADDRESS_PLACEHOLDER: 'Inserisci via e n. civico',
  CIP_PROCEEDING_ADD_OFFICE_CITY_LABEL: 'Città',
  CIP_PROCEEDING_ADD_OFFICE_CITY_PLACEHOLDER: 'Inserisci città sede',
  CIP_PROCEEDING_ADD_OFFICE_CAP_LABEL: 'Cap',
  VALIDATIONS_PATTERN_CAP: 'CAP non idoneo',
  VALIDATIONS_PATTERN_EMAIL_MULTIPLE: 'Almeno un email risulta non valida',
  VALIDATIONS_PATTERN_PEC_MULTIPLE: 'Almeno un pec risulta non valida',
  VALIDATIONS_PATTERN_TELEFONO_MULTIPLE:
    'Almeno un telefono risulta non valido',
  CIP_PROCEEDING_ADD_OFFICE_CAP_PLACEHOLDER: 'Inserisci il CAP',
  CIP_PROCEEDING_ADD_OFFICE_NUMBER_LABEL: 'Telefono',
  CIP_PROCEEDING_ADD_OFFICE_NUMBER_LABEL_ERROR: 'Telefono non valido',
  CIP_PROCEEDING_ADD_OFFICE_NUMBER_PLACEHOLDER: 'Inserisci n. Telefono',
  CIP_PROCEEDING_ADD_OFFICE_NUMBER_PLACEHOLDER_MULTIPLE:
    'Inserimento multiplo di nr. telefonici, inserisci e premi Invio',
  CIP_PROCEEDING_ADD_OFFICE_PEC_LABEL: 'Pec',
  CIP_PROCEEDING_ADD_OFFICE_PEC_PLACEHOLDER: 'Inserisci la Pec',
  CIP_PROCEEDING_ADD_OFFICE_EMAIL_LABEL: 'Email',
  CIP_PROCEEDING_ADD_OFFICE_EMAIL_PLACEHOLDER: 'Inserisci indirizzo email',
  CIP_PROCEEDING_ADD_OFFICE_PEC_PLACEHOLDER_MULTIPLE:
    'Inserimento multiplo di pec, inserisci e premi Invio',
  CIP_PROCEEDING_ADD_OFFICE_EMAIL_PLACEHOLDER_MULTIPLE:
    'Inserimento multiplo di email, inserisci e premi Invio',
  CIP_PROCEEDING_ADD_NEW_OFFICE_SAVE_BUTTON: 'Salva',
  CIP_PROCEEDING_ADD_STEP2_BACK_BUTTON: 'Indietro',
  CIP_PROCEEDING_ADD_STEP2_NEXT_BUTTON: 'Prosegui',
  CIP_PROCEEDING_ADD_STEP3_BACK_BUTTON: 'Indietro',
  CIP_PROCEEDING_ADD_STEP3_NEXT_BUTTON: 'Salva e continua',
  CIP_PROCEEDING_ADD_STEP3_LENGUAGE_TITLE:
    'Inserisci i contenuti per la lingua',
  CIP_PROCEEDING_ADD_INFO_NAME_LABEL: 'Nome procedimento',
  CIP_PROCEEDING_ADD_INFO_NAME_PLACEHOLDER: 'Inserisci titolo procedimento',
  CIP_PROCEEDING_ADD_INFO_INFO_LABEL: 'Informazioni principali (sintesi)',
  CIP_PROCEEDING_ADD_INFO_INFO_PLACEHOLDER:
    'Inserisci testo di descrizione procedimento',
  CIP_PROCEEDING_ADD_INFO_INFO_RICH_TEXT_LABEL: 'Informazioni principali',
  CIP_PROCEEDING_ADD_INFO_INFO_RICH_TEXT_PLACEHOLDER:
    'Inserisci testo di descrizione procedimento',
  CIP_PROCEEDING_ADD_INFO_COMUNICAZIONE_RICH_TEXT_LABEL:
    'Come ricevere le comunicazioni - Come consultare il proprio fascicolo',

  CIP_PROCEEDING_ADD_INFO_WHERE_LABEL: 'Testo introduttivo dove rivolgersi',
  CIP_PROCEEDING_ADD_INFO_WHERE_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEEDING_ADD_INFO_NOTE_LABEL: 'Note',
  CIP_PROCEEDING_ADD_INFO_NOTE_PLACEHOLDER: 'Inserisci testo note',
  CIP_PROCEEDING_ADD_INFO_WHO_LABEL: 'Chi può presentare la domanda (sintesi)',
  CIP_PROCEEDING_ADD_INFO_WHO_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEEDING_ADD_INFO_WHO_RICH_TEXT_LABEL: 'Chi può presentare la domanda',
  CIP_PROCEEDING_ADD_INFO_REQUIREMENTS_LABEL: 'Come presentare l\'istanza',
  CIP_PROCEEDING_ADD_INFO_REQUIREMENTS_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEEDING_ADD_INFO_DOC_LABEL: 'Quali documenti presentare',
  CIP_PROCEEDING_ADD_INFO_DOC_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEEDING_ADD_INFO_TERM_LABEL:
    'Termini per la presentazione della domanda',
  CIP_PROCEEDING_ADD_INFO_TERM_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEEDING_ADD_INFO_CONCLUSION_LABEL:
    'Termini per la conclusione -  Termini per il ricorso',
  CIP_PROCEEDING_ADD_INFO_HELP_ONLINE_LABEL: 'Help online',
  CIP_PROCEEDING_ADD_INFO_HELP_ONLINE_PLACEHOLDER:
    'Inserisci testo suggerimenti',
  CIP_PROCEEDING_ADD_INFO_CONCLUSION_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEEDING_ADD_INFO_PRIVACY_LABEL: 'Informativa privacy',
  CIP_PROCEEDING_ADD_INFO_PRIVACY_PLACEHOLDER:
    'Inserisci testo informativa privacy',
  CIP_PROCEEDING_ADD_INFO_NORM_LABEL: 'Normativa',
  CIP_PROCEEDING_ADD_INFO_NORM_PLACEHOLDER: 'Inserisci testo',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_WHERE_TO_ASK_PHONE: 'Telefono',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_WHERE_TO_ASK_EMAIL: 'Email',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_WHERE_TO_ASK_PEC: 'PEC',
  CIP_PROCEDIMENTO_TAB_NOT_UFFICI_1:
    'Siamo spiacenti. Questo procedimento non è seguito da nessun ufficio.',
  CIP_PROCEDIMENTO_TAB_NOT_UFFICI_2:
    'La preghiamo di contattare l\'assistenza o di abbinare un ufficio a questo procedimento nella sezione Modifica Procedimento',

  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_FAQ_WHO:
    'Chi può presentare la domanda',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_FAQ_WHAT_NEEDED:
    'Come presentare l\'istanza',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_FAQ_WHAT_DOCUMENT:
    'Quali documenti presentare',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_FAQ_PRESENTATION:
    'Termini per la presentazione della domanda',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_FAQ_END:
    'Termini per la conclusione - Termini per il ricorso',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_FAQ_LAW: 'Normativa',
  CIP_PROCEDIMENTO_TAB_DATA_SECTIONS_MORE_INFO_COM:
    'Come ricevere le comunicazioni - Come consultare il proprio fascicolo',

  CIP_PROCEDIMENTO_PROCEDIMENTI: 'Procedimenti',
  CIP_PROCEDIMENTO_TAG: 'Tag',
  CIP_PROCEDIMENTO_ORG: 'Organizzazione',
  CIP_PROCEDIMENTO_BREADCRUMB_HOME: 'Home',
  CIP_PROCEDIMENTO_BREADCRUMB_ADMIN: 'Amministrazione',
  CIP_PROCEDIMENTO_BREADCRUMB_MANAGE: 'Gestione Procedimenti',
  CIP_PROCEDIMENTO_BREADCRUMB_MANAGE_LINGUE: 'Gestione Lingue',

  CIP_PROCEDIMENTO_LIST_BUTTONS_LANGUAGES_TITLE: 'Gestione lingue',
  CIP_PROCEDIMENTO_LIST_BUTTONS_PUBLISH_TITLE: 'Gestisci pubblicazione',
  CIP_PROCEDIMENTO_LIST_BUTTONS_EDIT_TITLE: 'Modifica',
  CIP_PROCEDIMENTO_LIST_BUTTONS_DELETE_TITLE: 'Elimina',

  CIP_PROCEDIMENTO_LIS_ERROR:
    'Impossibile recuperare la lista dei procedimenti, riprova più tardi',
  CIP_PROCEDIMENTO_LIST_TITLE: 'Lista Procedimenti',
  CIP_PROCEDIMENTO_LIST_BUTTON_NEW_PROCEDIMENTO: 'Nuovo procedimento',
  CIP_PROCEDIMENTO_DETAIL_KIND_TAG_LIST_FILTER_BUTTON: 'Filtra procedimento',
  CIP_PROCEDIMENTO_DETAIL_TAG_LIST_FILTER_BUTTON: 'Filtra Tag',
  CIP_PROCEDIMENTO_DETAIL_ORGANIZZAZIONE_LIST_FILTER_BUTTON:
    'Filtra organizzazione',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_STRUTTURA_LABEL: 'Struttura organizzativa',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_NOME_LABEL: 'Nome procedimento',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_NOME_PLACEHOLDER: 'Cerca per nome',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_CODE_LABEL: 'Codice procedimento',
  IP_PROCEDIMENTO_LIST_FILTER_FORM_LABEL_TITLE: 'Titolo procedimento',

  CIP_PROCEDIMENTO_LIST_FILTER_FORM_CODE_PLACEHOLDER: 'Cerca per codice',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_STATO_LABEL: 'Stato di pubblicazione',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_HP_LABEL: 'Procedimento in HP',
  CIP_PROCEDIMENTO_LIST_FILTER_FORM_TITLE_LABEL: 'Titolo procedimento',

  CIP_PROCEDIMENTO_LIST_COLUMNS_STATO_PUBBLICATO: 'Pubblicato',
  CIP_PROCEDIMENTO_LIST_COLUMNS_STATO_DA_PUBBLICARE: 'Da pubblicare',
  CIP_PROCEDIMENTO_LIST_COLUMNS_STATO_BOZZA: 'Bozza',
  CIP_PROCEDIMENTO_LIST_COLUMNS_STATO_: '--',
  CIP_PROCEDIMENTO_LIST_COLUMNS_LIVELLO_ONLINE: 'Online',
  CIP_PROCEDIMENTO_LIST_COLUMNS_LIVELLO_PARZIALMENTE_ONLINE:
    'Parzialmente online',
  CIP_PROCEDIMENTO_LIST_COLUMNS_LIVELLO_NON_INFORMATIZZATO:
    'Non informatizzato',
  CIP_PROCEDIMENTO_LIST_COLUMNS_LIVELLO_INFORMATIZZATO: 'Informatizzato',
  CIP_PROCEDIMENTO_LIST_COLUMNS_LIVELLO_: '--',
  CIP_PROCEDIMENTO_LIST_COLUMNS_NAME: 'Nome procedimento',
  CIP_PROCEDIMENTO_LIST_COLUMNS_CATEGORY: 'Categoria',
  CIP_PROCEDIMENTO_LIST_COLUMNS_PRIORITY: 'Priorità',
  CIP_PROCEDIMENTO_LIST_COLUMNS_STATE: 'Stato',
  CIP_PROCEDIMENTO_LIST_COLUMNS_CODICE: 'Codice',
  CIP_PROCEDIMENTO_LIST_COLUMNS_CLASSIFICAZIONE: 'Classificazione',
  CIP_PROCEDIMENTO_LIST_COLUMNS_STRUCTURE: 'Struttura',

  CIP_PROCEDIMENTO_LIST_POP_CONFIRM_DELETE_TITLE: 'Rimuovi procedimento',
  CIP_PROCEDIMENTO_LIST_POP_CONFIRM_DELETE_TEXT:
    'Confermi di voler rimuovere il procedimento selezionato?',

  CIP_PROCEDIMENTO_LIST_POP_LINGUE_TITLE: 'Gestione lingue',
  CIP_PROCEDIMENTO_LIST_POP_LINGUE_SUB_TITLE_NAME: 'Nome Procedimento',
  CIP_CATEGORIAPROCEDIMENTO_LIST_POP_LINGUE_SUB_TITLE_NAME:
    'Nome Categoria Procedimenti',
  CIP_TIPOTAG_LIST_POP_LINGUE_SUB_TITLE_NAME: 'Nome Categoria Tag',
  CIP_TAG_LIST_POP_LINGUE_SUB_TITLE_NAME: 'Nome Tag',
  CIP_PROCEDIMENTO_LIST_POP_LINGUE_SUB_TITLE_STATE: 'Stato pubblicazione',
  CIP_PROCEDIMENTO_LIST_POP_LINGUE_COMPLITE: 'Completo',
  CIP_PROCEDIMENTO_LIST_POP_LINGUE_NOT_COMPLITE: 'Incompleto',
  CIP_PROCEDIMENTO_LIST_POP_LINGUE_NAME: 'Nome lingua',
  CIP_PROCEDIMENTO_LIST_POP_LINGUE_STATE: 'Stato completamento',

  CIP_PROCEDIMENTO_LIST_FETCH_ERROR_TITLE: 'Errore',
  CIP_PROCEDIMENTO_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i procedimenti, riprova più tardi',
  CIP_PROCEDIMENTO_LIST_ORG_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare le strutture organizzative, riprova più tardi',

  CIP_PROCEDIMENTO_LIST_HOMEPAGE_PUBLISHED:
    'Pubblicato in home',
  CIP_PROCEDIMENTO_LIST_MODAL_UNPUBLISH_TITLE:
    'Rimuovi procedimento dalla pubblicazione',
  CIP_PROCEDIMENTO_LIST_MODAL_UNPUBLISH_PARAGRAPH:
    'Stai rimuovendo dalla pubblicazione la scheda procedimento disponibile sul front end nelle seguenti lingue:',
  CIP_PROCEDIMENTO_LIST_MODAL_UNPUBLISH_QUEST:
    'Confermi la rimozione del procedimento dal catalogo visibile sul sito?',

  CIP_PROCEDIMENTO_LIST_POP_PROCEDIMENTO_TITLE: 'Pubblica procedimento',
  CIP_PROCEDIMENTO_LIST_POP_PROCEDIMENTO_FRASE:
    'Confermando la pubblicazione la scheda procedimento sarà disponibile sul front end nelle seguenti lingue:',
  CIP_PROCEDIMENTO_LIST_POP_PROCEDIMENTO_QUEST:
    'Confermi la pubblicazione del procedimento?',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_DELETED_MESSAGE:
    'Non è stato possibile eliminare il procedimento selezionato, riprova più tardi',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_DELETED_TITLE: 'Procedimento eliminato',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_DELETED_MESSAGE:
    'Il procedimento selezionato è stato correttamente eliminato',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_EXPORT_MESSAGE:
    'Impossibile esportare il file richiesto, riprova più tardi ',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_EXPPORT_TITLE: 'Operazione completata',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_EXPORT_MESSAGE:
    'File esportato con successo',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_DETAIL_MESSAGE:
    'Non è stato possibile recuperare il procedimento richiesto, riprova più tardi',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_PUBBLICATION_TITLE:
    'Procedimento pubblicato',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_PUBBLICATION_MESSAGE:
    'Il procedimento è stato pubblicato con successo',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_UNPUBBLICATION_TITLE:
    'Procedimento ritirato',
  CIP_PROCEDIMENTO_LIST_TOASTR_SUCCESS_UNPUBBLICATION_MESSAGE:
    'Il procedimento è stato rimosso dalla pubblicazione con successo',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_PUBBLICATION_TITLE: 'Errore',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_PUBBLICATION_MESSAGE:
    'Non è stato possibile pubblicare il procedimento, riprova più tardi',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_NO_OFFICE_MESSAGE:
    'Non è possibile pubblicare un procedimento senza uffici',
  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_LINGUE_MESSAGE:
    `Impossibile recuperare l'informazione richiesta, riprova più tardi`,
  CIP_PROCEDIMENTO_DETAIL_ERROR_PROCEDIMENTI_CORRELATI_MESSAGE:
    'Non è stato possibile recuperare i procedimenti correlati di questo procedimento, riprova più tardi',
  CIP_TIPOTAG_LIST_TOASTR_ERROR_NOT_LINGUE_MESSAGE: 'Il tipo Tag non ha lingue',
  CIP_TAG_LIST_TOASTR_ERROR_NOT_LINGUE_MESSAGE: 'Il Tag non ha lingue',
  CIP_CATEGORIA_LIST_TOASTR_ERROR_NOT_LINGUE_MESSAGE:
    'La categoria procedimento non ha lingue',

  CIP_PROCEDIMENTO_LIST_TOASTR_ERROR_NOT_LINGUE_MESSAGE:
    'Il procedimento non ha lingue',
  CIP_PROCEDIMENTO_LIST_TAG_TITLE: 'Associa Tag',
  CIP_PROCEDIMENTO_LIST_CORR_TITLE: 'Associa Procedimenti correlati',

  CIP_PROCEDIMENTO_LIST_TAG_ERROR_TEXT:
    'Non è stato possibile recuperare i Tag, riprova più tardi',

  // STEP 4
  CIP_PROCEEDING_DOC_NO_RESULT: 'Nessun documento presente',
  CIP_PROCEDIMENTO_ADD_BUTTON_NEW_DOCUMENTO: 'Nuovo documento',
  CIP_PROCEDIMENTO_LIST_DOCUMENT_ERROR_TEXT:
    'Non è stato possibile recuperare i documenti, riprova più tardi',
  CIP_PROCEDIMENTO_ADD_TABLE_DOCUMENTO_NOME: 'Nome documento',
  CIP_PROCEDIMENTO_ADD_TABLE_DOCUMENTO_DATA: 'Data caricamento',
  CIP_PROCEDIMENTO_ADD_OK_CLOSE_TITLE: 'Procedimento inserito',
  CIP_PROCEDIMENTO_ADD_OK_CLOSE_MESSAGE:
    'Il procedimento è stato salvato ed è pronto per essere pubblicato',
  CIP_PROCEDIMENTO_ADD_CHIUDI_PUBBLICA_TITLE: 'Procedimento Pubblicato',
  CIP_PROCEDIMENTO_ADD_CHIUDI_PUBBLICA_MESSAGE:
    'Il procedimento è stato pubblicato ed è visibile nel front end',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_TITLE: 'Nuovo documento',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_TEXT:
    'Seleziona il file da caricare e premi Carica',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_BUTTON_INPUT_FILE: 'Scegli',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_LABEL_INPUT_FILE:
    'Puoi allegare solo file .pdf, .jpg, .png, .tiff, .p7m (dimensione massima 10mb)',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_LABEL_INPUT_TEXT: 'Nome file',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_PLACEHOLDER_INPUT_TEXT:
    'Inserisci nome del documento',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_BUTTON_CONFIRM: 'Carica',

  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_SUCCESS_TITLE: 'Documento salvato',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_SUCCESS_MESSAGE:
    'Il documento è stato correttamente salvato',

  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_ERROR_SIZE_MESSAGE:
    'Superata dimensione massima, non superare i 5mb',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_ERROR_EXTENCION_MESSAGE:
    'Estensione del file non valida',
  CIP_PROCEDIMENTI_ADD_NEW_DOCUMENT_ERROR_MESSAGE:
    "È stato riscontrato un errore durante il caricamento del file, riprova più tardi",
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_ERROR_DELETED_MESSAGE:
    'Non è stato possibile eliminare il documento selezionato, riprova più tardi',
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_POP_CONFIRM_DELETE_TITLE:
    'Rimuovi documento',
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_POP_CONFIRM_DELETE_TEXT:
    'Confermi di voler rimuovere il documento selezionato?',
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_SUCCESS_DELETED_TITLE:
    'Documento eliminato',
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_SUCCESS_DELETED_MESSAGE:
    'Il documento selezionato è stato correttamente eliminato',
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_ERROR_SCARICARE_MESSAGE:
    'Non è stato possibile scaricare il documento selezionato, riprova più tardi',
  CIP_PROCEDIMENTO_NEW_TOASTR_ERROR_CREATE_TAG_MESSAGE:
    'Bisogna inserire almeno un TAG',
  CIP_PROCEDIMENTO_NEW_TOASTR_ERROR_CREATE_STRUCT_MESSAGE:
    'Bisogna inserire almeno un\'organizzazione',
  CIP_PROCEDIMENTO_PUBBLISH_ERROR_NOT_LINGUE_MESSAGE:
    'Non è possibile pubblicare un procedimento senza lingue',
  CIP_PROCEDIMENTO_PUBBLISH_ERROR_NOT_LINGUE_TITLE:
    'Errore nella pubblicazione',
  CIP_PROCEDIMENTO_PUBBLISH_ERROR_LINGUA_MESSAGE:
    'Non è possibile pubblicare un procedimento senza che abbia almeno una lingua completa',
  CIP_PROCEDIMENTO_PUBBLISH_ERROR_BOZZA_MESSAGE:
    'Non è possibile pubblicare una bozza di procedimento',
  CIP_PROCEDIMENTO_PUBBLISH_SUCCESS_TITLE: 'Procedimento già pubblicato',
  // STEP 2
  CIP_PROCEDIMENTO_STEP2_LIST_OFFICE_EMPTY_MESSAGE: 'Nessun ufficio trovato',
  CIP_PROCEDIMENTO_STEP2_LIST_OFFICE_COLUMN_NAME: 'Nome ente',
  CIP_PROCEDIMENTO_STEP2_LIST_OFFICE_COLUMN_ADDRESS: 'Indirizzo ente',
  CIP_PROCEDIMENTO_STEP2_LIST_OFFICE_COLUMN_CITY: 'Città ente',

  // TOASTR
  CIP_PROCEDIMENTO_STEP2_LIST_OFFICE_FETCH_ERROR_TITLE: 'Errore',
  CIP_PROCEDIMENTO_STEP2_LIST_OFFICE_FETCH_ERROR_MESSAGE:
    'Non è stato possibile recuperare i vari uffici, riprova più tardi',

  CIP_PROCEDIMENTO_STEP2_OFFICE_INSERT_SUCCESS_TITLE: 'Ufficio salvato',
  CIP_PROCEDIMENTO_STEP2_OFFICE_INSERT_SUCCESS_MESSAGE:
    'Il nuovo ufficio è stato correttamente salvato',
  CIP_PROCEDIMENTO_STEP2_OFFICE_INSERT_ERROR_TITLE: 'Errore',
  CIP_PROCEDIMENTO_STEP2_OFFICE_INSERT_ERROR_MESSAGE:
    'Non è stato possibile salvare il nuovo ufficio, riprova più tardi',

  CIP_PROCEDIMENTO_STEP2_OFFICE_EDIT_SUCCESS_TITLE: 'Ufficio modificato',
  CIP_PROCEDIMENTO_STEP2_OFFICE_EDIT_SUCCESS_MESSAGE:
    'L\'ufficio selezionato è stato correttamente modificato',
  CIP_PROCEDIMENTO_STEP2_OFFICE_EDIT_ERROR_TITLE: 'Errore',
  CIP_PROCEDIMENTO_STEP2_OFFICE_EDIT_ERROR_MESSAGE:
    'Non è stato possibile salvare le modifiche dell ufficio selezionato, riprova più tardi',

  CIP_PROCEDIMENTO_STEP2_OFFICE_DELETE_SUCCESS_TITLE: 'Ufficio eliminato',
  CIP_PROCEDIMENTO_STEP2_OFFICE_DELETE_SUCCESS_MESSAGE:
    'L\'ufficio selezionato è stato correttamente eliminato',
  CIP_PROCEDIMENTO_STEP2_OFFICE_DELETE_ERROR_TITLE: 'Errore',
  CIP_PROCEDIMENTO_STEP2_OFFICE_DELETE_ERROR_MESSAGE:
    'Non è stato possibile eliminare l\' ufficio selezionato, riprova più tardi',

  // STEP 3
  CIP_PROCEDIMENTO_STEP3_INFO_INSER_ERROR_TITLE: 'Errore',
  CIP_PROCEDIMENTO_STEP3_INFO_INSER_ERROR_MESSAGE:
    'Non è stato possibile salvare i dati inseriti, riprova più tardi',
  CIP_PROCEDIMENTO_STEP3_SAVE_SUCCESS_TITLE: 'Lingua Modificata',
  CIP_PROCEDIMENTO_STEP3_SAVE_SUCCESS_MESSAGE:
    'La lingua è stata modificata con successo',
  CIP_PROCEDIMENTO_GESTIONELINGUE_TITLE: 'Gestione lingue procedimento',

  CIP_PROCEDIMENTO_STEP1_PROCEDIMENTI_CORR_WARNING_MESSAGE:
    'Non puoi scegliere il procedimento che stai modificando, è stato rimosso dalla selezione.',

  MGU_ORGANITATIONS_LIST_FILTER_FORM_CATEGORY_LABEL: 'Categorie',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_CATEGORY_PLACEHOLDER:
    'Cerca per categoria',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_NAME_LABEL: 'Denominazione',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_NAME_PLACEHOLDER: 'Cerca per nome ufficio',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_FISCAL_CODE_LABEL: 'Codice Fiscale',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_FISCAL_CODE_PLACEHOLDER:
    'Cerca per CF ufficio',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_PIVA_LABEL: 'Partita Iva',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_PIVA_PLACEHOLDER:
    'Cerca per P.Iva ufficio',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_OFFICE_CODE_LABEL: 'Codice ufficio',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_OFFICE_CODE_PLACEHOLDER:
    'Cerca per codice ufficio',
};
