import { Component, Input } from '@angular/core';
import { AutocompleteInput } from '../../../models/inputs/autocomplete-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-autocomplete-input',
  template: `
    <ng-select
      bindLabel="label"
      bindValue="value"
      [minTermLength]="control.minTermLength"
      [typeToSearchText]="control.typeToSearchText | translate"
      [notFoundText]="control.notFoundText | translate"
      [typeahead]="control.typeahead"
      [clearable]="control.clearable"
      [multiple]="control.multiple"
      [searchable]="control.searchable"
      [items]="control.options | async"
      [loading]="control.loading"
      [formControl]="control"
      [placeholder]="control.placeholder | translate"
      [appColorStatus]="control.colorStatus"
      [addTag]="control.addTag"
      [selectOnTab]="control.selectOnTab"
      [isOpen]="control.isOpen"
      [hideSelected]="true"
    >
      <ng-template
        *ngIf="control.addTag"
        ng-label-tmp
        let-item="item"
        let-clear="clear"
      >
        <span class="ng-value-label">
          {{ item.value || item || item.label }}</span
        >
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </ng-template>
      <ng-template
        *ngIf="control.templates"
        ng-label-tmp
        ng-reflect-ng-item-label
        let-item="item"
        ngClass="w-100"
      >
        <div class="row ml-1 mr-auto">
          <div
            style="background: #{{
              item.value
            }}; width: 15px; height: 15px; border-radius: 50%;"
            class="mr-1 mt-1"
          ></div>
          <div class="ng-value-label">{{ item.label }}</div>
        </div>
      </ng-template>
      <ng-template
        *ngIf="control.templates"
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <div class="row ml-1 mr-auto">
          <div
            style="background: #{{
              item.value
            }}; width: 15px; height: 15px; border-radius: 50%;"
            class="mr-1 mt-1"
          ></div>
          <div class="ng-value-label">{{ item.label }}</div>
        </div>
      </ng-template>
    </ng-select>
  `,
})
export class AutocompleteInputComponent extends BaseInputComponent {
  @Input() control: AutocompleteInput;
}

/* <ng-select
      bindLabel="label"
      [minTermLength]="control.minTermLength"
      [typeToSearchText]="control.typeToSearchText | translate"
      [notFoundText]="control.notFoundText | translate"
      [typeahead]="control.typeahead"
      [clearable]="control.clearable"
      [loading]="control.loading"
      [items]="control.options | async"
      [formControl]="control"
      [placeholder]="control.placeholder | translate"
      [appColorStatus]="control.colorStatus"
    >
    </ng-select> */
