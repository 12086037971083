import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    SharedModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'assets/js/tinymce/tinymce.min.js',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
