import { Component, Input } from '@angular/core';
import { tinyConfig } from 'src/app/config/tinymce.config';
import { RichTextInput } from '../../../models/inputs/rich-text-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-rich-text-input',
  template: ` <editor [formControl]="control" [init]="tinyConfig"></editor> `,
})
export class RichTextInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: RichTextInput;

  tinyConfig = tinyConfig;
}
