export const tinyConfig = {
  height: 300,
  content_style:
    '.tiny_mce-bold { font-weight: bold; }' +
    '.tiny_mce-align-left {text-align: left;}' +
    '.tiny_mce-align-center { text-align: center; }' +
    '.tiny_mce-align-right { text-align: right; }' +
    '.tiny_mce-underline {text-decoration: underline; }' +
    '.tiny_mce-strikethrough {text-decoration: line-through; }',
  menubar: false,
  formats: {
    bold: { inline: 'span', classes: 'tiny_mce-bold' },
    alignleft: {
      selector: 'p,pre,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
      classes: 'tiny_mce-align-left',
    },
    alignright: {
      selector: 'p,pre,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
      classes: 'tiny_mce-align-right',
    },
    aligncenter: {
      selector: 'p,pre,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
      classes: 'tiny_mce-align-center',
    },
    underline: {
      inline: 'span',
      classes: 'tiny_mce-underline',
      exact: true,
    },
    strikethrough: {
      inline: 'span',
      classes: 'tiny_mce-strikethrough',
      exact: true,
    },
  },

  plugins: [
    'autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ],
  toolbar:
    'undo redo | formatselect | underline strikethrough bold italic subscript superscript | link | alignleft aligncenter alignright | bullist numlist | preview | removeformat | help',
};
