import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiCipPath = '/apicip/1.0';
const apiGeneralPath = '/apigeneral/1.0';
const apiMguPath = '/apimgu/1.0';

const backendApi: ApiConfig[] = [
  {
    name: 'getTranslate',
    method: 'GET',
    url:
      Adsp.configs.get('appHost') +
      '/general_lingua/{languageCode}/{module}.json',
  },
  {
    name: 'getCategoriaProcedimenti',
    method: 'GET',
    url: apiCipPath + '/categoriaprocedimenti',
  },
  {
    name: 'getCategoriaProcedimentiByUuid',
    method: 'GET',
    url: apiCipPath + '/categoriaprocedimenti/{uuid}',
  },
  {
    name: 'createCategoriaProcedimenti',
    method: 'POST',
    url: apiCipPath + '/categoriaprocedimenti',
  },
  {
    name: 'updateCategoriaProcedimenti',
    method: 'PATCH',
    url: apiCipPath + '/categoriaprocedimenti/{uuid}',
  },
  {
    name: 'deleteCategoriaProcedimenti',
    method: 'DELETE',
    url: apiCipPath + '/categoriaprocedimenti/{uuid}',
  },
  {
    name: 'getTipologieTags',
    method: 'GET',
    url: apiCipPath + '/categoriatag',
  },
  {
    name: 'getTipologiaTag',
    method: 'GET',
    url: apiCipPath + '/categoriatag/{uuid}',
  },
  {
    name: 'getTags',
    method: 'GET',
    url: apiCipPath + '/tag',
  },
  {
    name: 'creaTipoTag',
    method: 'POST',
    url: apiCipPath + '/categoriatag',
  },
  {
    name: 'creaTag',
    method: 'POST',
    url: apiCipPath + '/tag',
  },
  {
    name: 'modificaTipoTag',
    method: 'PATCH',
    url: apiCipPath + '/categoriatag/{uuid}',
  },
  {
    name: 'getTag',
    method: 'GET',
    url: apiCipPath + '/tag/{uuid}',
  },
  {
    name: 'modificaTag',
    method: 'PATCH',
    url: apiCipPath + '/tag/{uuid}',
  },
  {
    name: 'eliminaTipoTag',
    method: 'DELETE',
    url: apiCipPath + '/categoriatag/{uuid}',
  },
  {
    name: 'updateTagCategoria',
    method: 'PATCH',
    url: apiCipPath + '/tag/{uuid}/updatecategoria',
  },
  {
    name: 'eliminaTag',
    method: 'DELETE',
    url: apiCipPath + '/tag/{uuid}',
  },
  {
    name: 'exportTag',
    method: 'GET',
    url: apiCipPath + '/tag/export',
  },
  {
    name: 'exportTipoTag',
    method: 'GET',
    url: apiCipPath + '/categoriatag/export',
  },
  {
    name: 'getLingue',
    method: 'GET',
    url: apiGeneralPath + '/lingua',
  },
  {
    name: 'getLingueList',
    method: 'GET',
    url: apiGeneralPath + '/lingua/list',
  },
  {
    name: 'pubblicaProcedimento',
    method: 'PATCH',
    url: apiCipPath + '/procedimenti/publish/{uuid}',
  },
  {
    name: 'getProcedimenti',
    method: 'GET',
    url: apiCipPath + '/procedimenti',
  },
  {
    name: 'eliminaProcedimenti',
    method: 'DELETE',
    url: apiCipPath + '/procedimenti/{uuid}',
  },
  {
    name: 'modificaProcedimento',
    method: 'PATCH',
    url: apiCipPath + '/procedimenti/{uuid}',
  },
  {
    name: 'exportProcedimenti',
    method: 'GET',
    url: apiCipPath + '/procedimenti/export',
  },
  {
    name: 'struttureOrganizzative',
    method: 'GET',
    url: apiMguPath + '/organizzazione/list',
  },
  {
    name: 'categorieOrganizzazioni',
    method: 'GET',
    url: apiMguPath + '/config/staticList/categorieOrganizzazione',
  },
  {
    name: 'statiPubblicazione',
    method: 'GET',
    url: apiCipPath + '/config/staticList/statoPubblicazione',
  },
  {
    name: 'getProcedimento',
    method: 'GET',
    url: apiCipPath + '/procedimenti/{uuid}/{codiceLingua}',
  },
  {
    name: 'getProceedingInfoLiv',
    method: 'GET',
    url: apiCipPath + '/config/staticList/livelliInformatizzazione',
  },
  {
    name: 'getProceedingsOrganizzations',
    method: 'GET',
    url: apiCipPath + '/org/list',
  },
  {
    name: 'getProceedingsRelated',
    method: 'GET',
    url: apiCipPath + '/procedimenti',
  },
  {
    name: 'saveProceeding',
    method: 'POST',
    url: apiCipPath + '/procedimenti',
  },
  {
    name: 'getOffices',
    method: 'GET',
    url: apiCipPath + '/procedimenti/{uuidProcedimento}/uffici',
  },
  {
    name: 'addOffice',
    method: 'POST',
    url: apiCipPath + '/procedimenti/{uuidProcedimento}/uffici',
  },
  {
    name: 'updateOffice',
    method: 'PATCH',
    url: apiCipPath + '/procedimenti/uffici/{uuidUfficio}',
  },
  {
    name: 'deleteOffice',
    method: 'DELETE',
    url: apiCipPath + '/procedimenti/uffici/{uuidUfficio}',
  },
  {
    name: 'updateInfoProcess',
    method: 'PATCH',
    url: apiCipPath + '/infoprocedimenti/{uuid}',
  },
  {
    name: 'getProcedimentiCorrelati',
    method: 'GET',
    url: apiCipPath + '/procedimenti/{uuid}/correlati/{codiceLingua}',
  },
  {
    name: 'getDocumentByProcedimento',
    method: 'GET',
    url: apiCipPath + '/procedimenti/{uuidProcedimento}/documenti',
  },
  {
    name: 'caricaDocumento',
    method: 'POST',
    url: apiCipPath + '/procedimenti/{uuidProcedimento}/documenti',
  },
  {
    name: 'scaricaDocumento',
    method: 'GET',
    url:
      apiCipPath + '/procedimenti/{uuidProcedimento}/documenti/{uuidDocumento}',
  },
  {
    name: 'deletDocument',
    method: 'DELETE',
    url:
      apiCipPath + '/procedimenti/{uuidProcedimento}/documenti/{uuidDocumento}',
  },
  {
    name: 'getLinguaProcess',
    method: 'GET',
    url: apiCipPath + '/procedimenti/multilang/{uuid}',
  },
  {
    name: 'getLinguaTipoTag',
    method: 'GET',
    url: apiCipPath + '/categoriatag/multilang/{uuid}',
  },
  {
    name: 'getLinguaTag',
    method: 'GET',
    url: apiCipPath + '/tag/multilang/{uuid}',
  },
  {
    name: 'getLinguaCategoria',
    method: 'GET',
    url: apiCipPath + '/categoriaprocedimenti/multilang/{uuid}',
  },
  {
    name: 'getLingueAbilitate',
    method: 'GET',
    url: apiGeneralPath + '/lingua',
  },
];

export const backendConfig: Omit<
  BackendConfig,
  'getApiConfig' | 'prepareUrl'
> = {
  baseUrl: Adsp.configs.get('baseApiUrl'),
  api: backendApi,
};
