import { AbstractControl, ValidatorFn } from '@angular/forms';

const patterFn = (control, regex: RegExp) => {
  return control.value?.every((value) => regex.test(value));
};

export function MultipleValidator(pattern: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex: RegExp = new RegExp(pattern);
    if (control.value && pattern) {
      if (!patterFn(control, regex)) {
        return { SINGLE_NOT_VALID: true };
      } else {
        return null;
      }
    }

    return null;
  };
}
