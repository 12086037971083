import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ApiClientModule } from '@eng-ds/api-client';
import { TranslateModule } from '@eng-ds/translate';
import { IT } from 'src/assets/i18n/IT';
import { backendConfig } from '../config/backend.config';
import { AppHttpInterceptor } from './services/app-http.interceptor';
import { AuthGuard } from './services/auth.guard';
import { CodiceVerticaleResolver } from './services/codiceVerticale.resolver';
import { TranslateService } from './services/translate.service';

@NgModule({
  imports: [ApiClientModule, TranslateModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import Core modules in the AppModule only.'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...ApiClientModule.forRoot({
          backend: backendConfig,
        }).providers,
        ...TranslateModule.forRoot({
          langs: [
            {
              code: 'it',
              isDefault: true,
              label: 'Italiano',
              translations: IT,
            },
          ],
        }).providers,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AppHttpInterceptor,
          multi: true,
        },
        AuthGuard,
        CodiceVerticaleResolver,
        TranslateService,
      ],
    };
  }
}
