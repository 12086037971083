<div class="row">
  <div class="col-12">
    <div class="row">
      <div
        *ngIf="hasFile || control.placeholder"
        class="col-9 col-sm-9 col-md-9 d-flex align-items-center"
      >
        <span class="font-size-14">
          {{ hasFile ? file?.name : (control.placeholder | translate) }}
        </span>
      </div>
      <div class="col-3 col-sm-3 col-md-3">
        <agid-button
          class="float-right"
          icon
          size="small"
          [color]="hasFile ? 'danger' : 'primary'"
          (click)="btnClick()"
        >
          <agid-icon
            *ngIf="!hasFile"
            class="d-inline-flex"
            icon="it-search"
            color="white"
          >
          </agid-icon>
          <agid-icon
            *ngIf="hasFile"
            class="d-inline-flex"
            svg-sprite-path="/assets/svg/adsp_sprite.svg"
            icon="delete"
            color="white"
          >
          </agid-icon>
          <span style="margin-left: 0.25em" class="d-none d-md-inline">{{
            hasFile
              ? ("UTILS_REMOVE" | translate)
              : (control.buttonPlaceholder | translate)
          }}</span>
        </agid-button>

        <agid-button
          *ngIf="hasFile && control.showUploadBtn"
          class="ml-2"
          icon
          size="small"
          (click)="control.upload(file)"
        >
          <agid-icon class="d-inline-flex" icon="it-upload" color="white">
          </agid-icon>
          <span class="d-none d-md-inline">
            {{ control.labelUploadBtn | translate }}
          </span>
        </agid-button>
      </div>

      <input #fileTag type="file" class="d-none" />
    </div>
  </div>
</div>
